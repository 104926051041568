import React from "react";

import Hero from "../../components/Hero";
import ContactForm from "../../components/contact";

const ContactUs = () => {
  return (
    <div>
      <Hero greeting={`Contact With Us For Your Need`} type={`contact`} />
      <ContactForm />
    </div>
  );
};

export default ContactUs;
