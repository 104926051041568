import React from "react";

import elevator from "../../Assets/proudct/elevator-1.jpg";

import plannig from "../../Assets/images.jpg";
import supply from "../../Assets/download.jpg";
import installation from "../../Assets/images (1).jpg";
import MaM from "../../Assets/images (2).jpg";

const Reason = () => {
  return (
    <section className="sm:px-12 px-2 w-full  flex flex-col justify-center items-center  my-12">
      <div className="mb-4 sm:mb-8 font-raleway ">
        <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
          Why Us!
        </h2>
        <span className="text-green">
          You should place your trust in those you have reason to trust. The
          choice of whom to trust is yours to make
        </span>
      </div>
      <div>
        <p className="text-xl">
          <strong className="text-primary">Southtech Engineering Ltd </strong>
          is solely concentrated in Elevator, Escalator, Generator and
          Substation business including <strong>Elevator</strong>,
          <strong>Escalator</strong>, <strong>Generator</strong>
          and <strong>Substation</strong> planning, supply, installation,
          maintenance and modernization. The company was- in the joint registrar
          of companies.
        </p>

        <div className="grid grid-cols-2 md:px-12 md:grid-cols-4 w-full  gap-3 transition-all my-10">
          <div className="w-full flex flex-col ease-in-out duration-300 border-2 border-b-secondary bg-secondary rounded-md">
            <div className="w-full">
              <img
                src={plannig}
                alt=""
                className="w-full h-full object-contain relative"
              />
            </div>

            <p className="text-center text-white font-bold text-2xl pt-6">
              Planning
            </p>
          </div>
          <div className="w-full flex flex-col ease-in-out duration-300 border-2 border-b-secondary bg-secondary rounded-md">
            <div className="w-full">
              <img
                src={supply}
                alt=""
                className="w-full h-full object-contain relative"
              />
            </div>

            <p className="text-center text-white font-bold text-2xl pt-6">
              Supply
            </p>
          </div>
          <div className="ml-6 col-span-2">
            <h4 className="!mb-2 text-xl font-semibold font-nunito">
              Planning for Elevators and Escalators:
              <span className="text-sm sm:text-base pl-2 font-[400]">
                We provide proper planning for Elevator and Escalator. We
                ensure:
              </span>
            </h4>
            <div className="text-sm sm:text-lg font-nunito">
              <ul className="flex flex-col gap-3">
                <li>
                  <strong>Traffic flow:</strong> The number of people who will
                  use the elevators and escalators, and the times of day when
                  traffic will be heaviest.
                </li>
                <li>
                  <strong>Building height: </strong>The number of floors in the
                  building and the vertical distance that needs to be covered by
                  the elevators and escalators.
                </li>
                <li>
                  <strong>Building design: </strong> The layout of the building,
                  including the location of staircases, entrances, and exits,
                  and the flow of foot traffic.
                </li>
                <li>
                  <strong>User requirements: </strong>The needs of the users of
                  the building, including any specific accessibility
                  requirements.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2  md:grid-cols-12 w-full   gap-3 transition-all my-12">
          <div className="col-span-2 md:col-span-7">
            <h4 className="!mb-2 text-xl font-semibold font-nunito">
              Supply of Elevators and Escalators:
              <span className="text-sm sm:text-base pl-2 font-[400]">
                we maintain proper supply chain from the industry to house. We
                ensure:
              </span>
            </h4>
            <div className="text-sm sm:text-lg font-nunito">
              <ul className="flex flex-col gap-3">
                <li>
                  <strong>Quality and reliability: </strong>Elevators and
                  escalators are long-term investments, and as such, it is
                  important to select high-quality units that will provide
                  reliable and safe service over many years.
                </li>
                <li>
                  <strong>Cost: </strong>Elevators and escalators can be
                  expensive, and agencies should consider the costs of
                  procurement, installation, and maintenance over the life of
                  the units.
                </li>
                <li>
                  <strong>Maintenance and support: </strong>Elevators and
                  escalators require ongoing maintenance and support, and
                  agencies should consider the availability and quality of
                  maintenance services when selecting suppliers.
                </li>
                <li>
                  <strong>Energy efficiency: </strong>Elevators and escalators
                  can consume a significant amount of energy, and agencies
                  should consider the energy efficiency of different units when
                  making their selection.
                </li>
              </ul>
            </div>
            <div className="mt-5 sm:mt-10">
              <h4 className="!mb-2 text-xl font-semibold font-nunito">
                Installation of Elevators and Escalators:{" "}
              </h4>
              <p className="text-sm sm:text-lg font-nunito">
                Installation, maintenance, and modernization are all important
                considerations for elevators and escalators. By following strict
                safety guidelines during installation, performing regular
                maintenance tasks, and modernizing equipment as needed, building
                owners and managers can ensure the safe and reliable operation
                of their elevators and escalators over the long term.
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center col-span-5 ease-in-out duration-300 border-2 border-b-secondary  rounded-md">
            <div className="">
              <img
                src={installation}
                alt=""
                className="w-auto h-auto object-cover relative"
              />
            </div>

            <p className="text-center text-secondary font-bold text-2xl pt-6">
              Installation
            </p>
          </div>
        </div>
        <div className=" w-full gap-3 transition-all my-10">
          <div className=" flex flex-col justify-center items-center col-span-2 md:col-span-4 ease-in-out duration-300 border-2 border-b-secondary  rounded-md">
            <div className="">
              <img
                src={MaM}
                alt=""
                className="w-auto h-auto object-cover relative"
              />
            </div>

            <p className="text-center text-secondary font-bold text-2xl pt-6">
              Maintanance and Modarazation
            </p>
          </div>
          <div className="col-span-2 md:col-span-8">
            <p className="text-sm sm:text-lg font-nunito">
              SOUTHTECH is committed to run all its operations absolutely
              professionally by ensuring Safety & Securer and proper services to
              its clients, be it for a new supply and installation or for
              maintenance of Elevator, Escalators, Generator, and Substation or
              modernization of old Elevator, Escalators, Generator, Substation
              and service related all spare parts.
            </p>
            <ul>
              <li>
                <h5 className="mb-0">Engineering technologies</h5>
                <p className="text-sm sm:text-lg font-nunito">
                  We can orchestrate individual pieces of technical information
                  prepared by each design discipline into the comprehensive
                  system information, which is precisely coordinated with and
                  compatible to the procurement and/or the construction
                  functionalities.
                </p>
              </li>
              <li>
                <h5 className="mb-0">
                  Southtech Engineering Limited Values----
                </h5>
                <ul className="text-sm sm:text-lg font-nunito">
                  <li>· Safety & Secure Ensure </li>
                  <li>· Quality Maintain</li>
                  <li>· Create value for the customer</li>
                  <li>· Accountability</li>
                  <li>· Integrity & Trust</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reason;
