// function importAll(r) {
//   return r.keys().map(r);
// }
// export const images = importAll(
//   require.context("../Assets/slider", false, /\.(png|jpe?g|svg)$/)
// );

const photos = [
  {
    id: 1,
    url: require("../Assets/hero/photo-3.jpg"),
    caption: "Photo 1",
  },
  {
    id: 2,
    url: require("../Assets/hero/photo-4.jpg"),
    caption: "Photo 2",
  },
  {
    id: 3,
    url: require("../Assets/hero/phot-5.jpg"),
    caption: "Photo 3",
  },
  {
    id: 4,
    url: require("../Assets/hero/photo-1.jpg"),
    caption: "Photo 4",
  },
  {
    id: 5,
    url: require("../Assets/hero/photo-2.jpg"),
    caption: "Photo 4",
  },
  {
    id: 6,
    url: require("../Assets/hero/Cabin Design-2.jpg"),
    caption: "Photo 4",
  },
];

export { photos };
