import React from "react";
import FooterLogo from "../../Assets/Southtech Logo (1)-1.jpg";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="bg-secondary mt-6">
      <div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="flex justify-center text-teal-600">
          <div className="w-60">
            <img src={FooterLogo} alt="logo" className="w-auto h-auto" />
          </div>
        </div>

        <p className="mx-auto mt-6 max-w-xl text-center leading-relaxed text-white">
          Southtech Engineering Ltd is solely concentrated in Elevator,
          Escalator, Generator and Substation business including
          Elevator,Escalator, Generatorand Substation planning, supply,
          installation, maintenance and modernization.
        </p>
        <div className="mx-auto mt-6 max-w-xl text-center leading-relaxed text-white">
          <p className="mb-0 text-white">
            House: 92, Road: 15, Sector: 14, Uttara, Dhaka-1230
          </p>
          <p className="mb-0 text-white">Phone: +880 1713084548</p>
          <p className=" text-white">Email: southtech21bd@gmail.com</p>
        </div>

        <ul className="mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12">
          <li>
            <NavLink
              className="text-white transition hover:text-primary active:text-primary"
              to="/about"
            >
              About
            </NavLink>
          </li>

          <li>
            <NavLink
              className="text-white transition hover:text-primary active:text-primary"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </li>

          <li>
            <NavLink
              className="text-white transition hover:text-primary active:text-primary"
              to="/service"
            >
              Service
            </NavLink>
          </li>

          <li>
            <NavLink
              className="text-white transition hover:text-primary active:text-primary active:bg-transparent"
              to="/product"
            >
              Products
            </NavLink>
          </li>
        </ul>

        <div className="mt-6 flex justify-center items-center w-full text-white">
          All Right Reserve<span className="mx-1">™</span> southtech21bd.com
        </div>
      </div>
    </footer>
  );
};

export default Footer;
