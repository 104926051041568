import React from "react";
import ReactFlow, { Background } from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import resizableNode from "./resizableNode";

const nodeTypes = {
  resizableNode,
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 220;
const nodeHeight = 36;
const specialNodeWidth = 300; // Width for the special node with id "1"
const specialNodeHeight = 60;

const getLayoutedElements = (nodes, edges) => {
  dagreGraph.setGraph({ rankdir: "TB" });

  nodes.forEach((node) => {
    const width = node.id === "1" ? specialNodeWidth : nodeWidth;
    dagreGraph.setNode(node.id, { width, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = "top";
    node.sourcePosition = "bottom";

    // Adjust position to match React Flow's top-left anchor point
    const width = node.id === "1" ? specialNodeWidth : nodeWidth;
    const height = node.id === "1" ? specialNodeHeight : nodeHeight;
    node.position = {
      x: nodeWithPosition.x - width / 2,
      y: nodeWithPosition.y - height / 2,
    };
  });

  // Manual adjustments for specific nodes
  const node3 = nodes.find((n) => n.id === "3");
  const node4 = nodes.find((n) => n.id === "4");
  const node9 = nodes.find((n) => n.id === "9");
  const node5 = nodes.find((n) => n.id === "5");
  const node6 = nodes.find((n) => n.id === "6");
  const node7 = nodes.find((n) => n.id === "7");
  const node8 = nodes.find((n) => n.id === "8");
  const node10 = nodes.find((n) => n.id === "10");
  const node11 = nodes.find((n) => n.id === "11");
  const node12 = nodes.find((n) => n.id === "12");
  const node13 = nodes.find((n) => n.id === "13");
  const node14 = nodes.find((n) => n.id === "14");
  const node15 = nodes.find((n) => n.id === "15");
  const node16 = nodes.find((n) => n.id === "16");
  const node17 = nodes.find((n) => n.id === "17");
  const node18 = nodes.find((n) => n.id === "18");
  const node19 = nodes.find((n) => n.id === "19");
  const node20 = nodes.find((n) => n.id === "20");
  const node21 = nodes.find((n) => n.id === "21");

  if (node4 && node9) {
    node4.position = {
      x: node3.position.x - nodeWidth - 50,
      y: node3.position.y + 80,
    };
    node9.position = {
      x: node3.position.x + nodeWidth + 50,
      y: node3.position.y + 80,
    };
  }

  if (node5 && node6 && node7 && node8) {
    const baseY =
      Math.max(node4.position.y, node9.position.y) + nodeHeight + 160;
    const baseX = (node4.position.x + node9.position.x) / 2 - (nodeWidth + 50);
    node5.position = { x: baseX, y: baseY };
    node6.position = { x: baseX + nodeWidth + 50, y: baseY };
    node7.position = { x: baseX + 2 * (nodeWidth + 50), y: baseY };
    node8.position = { x: baseX + 3 * (nodeWidth + 50), y: baseY };
  }

  if (node10 && node11 && node12 && node13) {
    const baseY =
      Math.max(
        node5.position.y,
        node6.position.y,
        node7.position.y,
        node8.position.y
      ) +
      nodeHeight +
      80;
    node10.position = { x: node5.position.x - 120, y: baseY };
    node11.position = { x: node6.position.x - 100, y: baseY };
    node12.position = { x: node7.position.x - 80, y: baseY };
    node13.position = { x: node8.position.x - 60, y: baseY };
  }

  // Adjust positions for nodes 15 and 16 under node 14
  if (node14 && node15 && node16) {
    const baseY = node14.position.y + nodeHeight + 220;
    node15.position = { x: node14.position.x - nodeWidth / 2 + 150, y: baseY };
    node16.position = { x: node14.position.x + nodeWidth / 2 + 200, y: baseY };
  }
  if (node17 && node18) {
    const baseY = node11.position.y + nodeHeight + 100;
    node17.position = { x: node11.position.x - nodeWidth / 2, y: baseY };
    node18.position = { x: node11.position.x + nodeWidth / 2, y: baseY };
  }
  if (node19) {
    const baseY = node12.position.y + nodeHeight + 100;

    node19.position = { x: node12.position.x, y: baseY };
  }
  if (node20 && node21) {
    const baseY = node13.position.y + nodeHeight + 100;

    node20.position = { x: node13.position.x - 50, y: baseY };
    node21.position = { x: node13.position.x + nodeWidth / 2 + 70, y: baseY };
  }

  return { nodes, edges };
};

const initialNodes = [
  {
    id: "1",
    type: "ResizableNode",
    data: {
      label: (
        <div style={{ width: "300px" }}>
          {" "}
          {/* Adjust the width as needed */}
          <div className="flex">
            <img
              src="/Southtech Logo.png"
              alt="Logo"
              style={{ width: "30px" }}
            />
            <span style={{ fontSize: "18px" }}>SouthTech Engineering Ltd.</span>
          </div>
        </div>
      ),
    },
    position: { x: 0, y: 0 },
    style: {
      background: "#fff",
      border: "1px solid black",

      fontSize: 12,
      width: 280,
    },
  },

  { id: "2", data: { label: "Advisor" }, position: { x: 0, y: 0 } },
  { id: "3", data: { label: "Managing Director" }, position: { x: 0, y: 0 } },
  {
    id: "4",
    data: { label: "Deputy Managing Director" },
    position: { x: 0, y: 0 },
  },
  { id: "5", data: { label: "Admin" }, position: { x: 0, y: 0 } },
  { id: "6", data: { label: "Technical Director" }, position: { x: 0, y: 0 } },
  { id: "7", data: { label: "Accounts" }, position: { x: 0, y: 0 } },
  { id: "8", data: { label: "Sales Director" }, position: { x: 0, y: 0 } },
  { id: "9", data: { label: "Director" }, position: { x: 0, y: 0 } },
  { id: "10", data: { label: "Workshop" }, position: { x: 0, y: 0 } },
  {
    id: "11",
    data: { label: "Installation Incharge" },
    position: { x: 0, y: 0 },
  },
  {
    id: "12",
    data: { label: "Testing & commissioning Incharge" },
    position: { x: 0, y: 0 },
  },
  {
    id: "13",
    data: { label: "Service & Maintenance Incharge" },
    position: { x: 0, y: 0 },
  },
  {
    id: "14",
    data: { label: "Sales Manager" },
    position: { x: 0, y: 0 },
  },
  {
    id: "15",
    data: { label: "Executive-1" },
    position: { x: 0, y: 0 },
  },
  {
    id: "16",
    data: { label: "Executive-2" },
    position: { x: 0, y: 0 },
  },
  {
    id: "17",
    data: { label: "Installation team-1" },
    position: { x: 0, y: 0 },
  },
  {
    id: "18",
    data: { label: "Installation team-2" },
    position: { x: 0, y: 0 },
  },
  {
    id: "19",
    data: { label: "Testing & commissioning Team" },
    position: { x: 0, y: 0 },
  },
  {
    id: "20",
    data: { label: "Service Team-1" },
    position: { x: 0, y: 0 },
  },
  {
    id: "21",
    data: { label: "Service Team-2" },
    position: { x: 0, y: 0 },
  },
];

const initialEdges = [
  { id: "e1-2", source: "1", target: "2", type: "smoothstep", animated: true },
  { id: "e1-3", source: "1", target: "3", type: "smoothstep", animated: true },
  { id: "e3-4", source: "3", target: "4", type: "smoothstep", animated: true },
  { id: "e3-9", source: "3", target: "9", type: "smoothstep", animated: true },
  { id: "e3-5", source: "3", target: "5", type: "smoothstep", animated: true },
  { id: "e3-6", source: "3", target: "6", type: "smoothstep", animated: true },
  { id: "e3-7", source: "3", target: "7", type: "smoothstep", animated: true },
  { id: "e3-8", source: "3", target: "8", type: "smoothstep", animated: true },
  {
    id: "e4-10",
    source: "6",
    target: "10",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-11",
    source: "6",
    target: "11",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-12",
    source: "6",
    target: "12",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-13",
    source: "6",
    target: "13",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-14",
    source: "8",
    target: "14",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-15",
    source: "14",
    target: "15",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-16",
    source: "14",
    target: "16",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-17",
    source: "11",
    target: "17",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-18",
    source: "11",
    target: "18",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-19",
    source: "12",
    target: "19",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-20",
    source: "13",
    target: "20",
    type: "smoothstep",
    animated: true,
  },
  {
    id: "e4-21",
    source: "13",
    target: "21",
    type: "smoothstep",
    animated: true,
  },
];

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initialNodes,
  initialEdges
);

const FlowChart = () => {
  console.log("Nodes: ", layoutedNodes);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
      }}
      className="w-full h-[50vh] sm:h-[100vh]"
    >
      <div className="mb-2 sm:mb-8 font-raleway ">
        <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
          Organization Diagram
        </h2>
      </div>
      <div
        style={{
          width: "90%",
          height: "90%",
          border: "1px solid #ccc",
          overflow: "auto",
        }}
      >
        <ReactFlow
          nodes={layoutedNodes}
          edges={layoutedEdges}
          style={{ backgroundColor: "transparent" }}
          zoomOnScroll={false}
          panOnScroll={false}
          elementsSelectable={false}
          nodesDraggable={false}
          fitView
          nodeTypes={nodeTypes}
        >
          <Background />
          <style>{`.special-node { padding: 10px; }`}</style>{" "}
          {/* Add inline style */}
        </ReactFlow>
      </div>
    </div>
  );
};

export default FlowChart;
