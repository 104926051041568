import React from "react";

const Button = ({ content, type }) => {
  return (
    <>
      {type === "solid" && (
        <div
          className={`px-10 py-5 rounded-lg uppercase font-extrabold text-2xl bg-secondary hover:bg-primary text-white flex justify-center items-center text-center cursor-pointer shadow-2xl border border-white`}
        >
          {content}
        </div>
      )}
      {type === "text" && (
        <div
          className={`px-10 py-5 rounded-2xl uppercase font-extrabold text-2xl bg-whitesmoke hover:bg-primary text-secondary flex justify-center items-center text-center cursor-pointer shadow-2xl`}
        >
          {content}
        </div>
      )}
    </>
  );
};

export default Button;
