import React, { useState } from "react";
import Form from "../Form";
import { FaClock, FaHome, FaMailBulk, FaPhone } from "react-icons/fa";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
  };

  return (
    <section class="sm:px-12 px-2 w-full  flex flex-col justify-center items-center  my-12">
      <div className=" grid md:grid-cols-2 gap-6">
        <div>
          <Form formTitle="Contact For Support" type="help" />
        </div>
        <div className="mt-10 md:mt-0">
          <h2
            className={`uppercase text-[22px] font-bold  border-b-2 border-black `}
          >
            Contact Information
          </h2>
          <p className="text-[#777] pt-3">
            <span className="text-primary">Southtech Engineering Ltd</span> is a
            Considering the experience and well reputation of the partner in the
            sector of Elevator, Escalator, Generator & Substation in the world
            market I have decided to start the Supply, Installation, Testing,
            Commissioning and after sales service of Elevator, Escalator,
            Generator & Substation in Bangladesh.
          </p>
          <p className="flex gap-3 pt-5 items-center text-[#777]">
            <FaHome /> #Road: 15, Sector - 14, Uttara, Dhaka, Bangladesh
          </p>
          <p className="flex gap-3 pt-5 items-center text-[#777]">
            <FaPhone /> +880 1713 0845 48
          </p>
          <p className="flex gap-3 pt-5 items-center text-[#777]">
            <FaMailBulk /> southtech21bd@gmail.com
          </p>
          <p className="flex gap-3 pt-5 items-center text-[#777]">
            <FaClock /> 24/7 supports
          </p>
        </div>
      </div>{" "}
    </section>
  );
};

export default ContactForm;
