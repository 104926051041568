import { GiElevator } from "react-icons/gi";

const ProductGallery = () => {
  return (
    <section className="sm:px-12 px-2 w-full  flex flex-col justify-center items-center  my-12">
      <div className="mb-2 sm:mb-8 font-raleway ">
        <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
          All Products & Tools
        </h2>
      </div>
      <div className="w-full h-auto">
        <div className="w-full  h-auto pb-[2rem]">
          <p className="text-2xl font-bold flex justify-start items-center gap-3 border-b border-tahiti text-primary w-auto mb-1">
            <span>
              <GiElevator />
            </span>
            <span className="mb-3">ELevator</span>
          </p>
          <div className=" grid grid-cols-2 md:grid-cols-4 gap-3 shadow-md ">
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-500 transform hover:scale-125">
                <img
                  src="/elevator/e1.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/elevator/e2.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/elevator/e3.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/elevator/e4.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/elevator/e5.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/elevator/e6.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/elevator/e7.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/elevator/Elevator (1).jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  h-auto py-[2rem]">
          <p className="text-2xl font-bold flex justify-start items-center gap-3 border-b border-tahiti text-primary w-auto mb-1">
            <span>
              <GiElevator />
            </span>
            <span className="mb-3">Escalator</span>
          </p>
          <div className=" grid grid-cols-2 md:grid-cols-4 gap-3 shadow-md">
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-500 transform hover:scale-125">
                <img
                  src="/escalator/es1.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/escalator/es2.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/escalator/es3.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/escalator/es4.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/escalator/es5 (1).jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/escalator/es5 (2).jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/escalator/es5 (3).jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/escalator/es5 (4).jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  h-auto py-[2rem]">
          <p className="text-2xl font-bold flex justify-start items-center gap-3 border-b border-tahiti text-primary w-auto mb-1">
            <span>
              <GiElevator />
            </span>
            <span className="mb-3">COP</span>
          </p>
          <div className=" grid grid-cols-2 md:grid-cols-4 gap-3 shadow-md">
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-500 transform hover:scale-125">
                <img
                  src="/cop/COP-1.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/cop/COP-2.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/cop/COP-3.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/cop/COP-4.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  h-auto py-[2rem]">
          <p className="text-2xl font-bold flex justify-start items-center gap-3 border-b border-tahiti text-primary w-auto mb-1">
            <span>
              <GiElevator />
            </span>
            <span className="mb-3">Cabin Design</span>
          </p>
          <div className=" grid grid-cols-2 md:grid-cols-4 gap-3 shadow-md">
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-500 transform hover:scale-125">
                <img
                  src="/cabin/cd1.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/cabin/cd2.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/cabin/cd3.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/cabin/cd4.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  h-auto py-[2rem]">
          <p className="text-2xl font-bold flex justify-start items-center gap-3 border-b border-tahiti text-primary w-auto mb-1">
            <span>
              <GiElevator />
            </span>
            <span className="mb-3">Generator & Gearless Motor</span>
          </p>
          <div className=" grid grid-cols-2 md:grid-cols-4 gap-3 shadow-md">
            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-500 transform hover:scale-125">
                <img
                  src="/gg/gg1.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/gg/gg2.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/gg/gg3.png"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="w-full h-[300px] relative overflow-hidden">
              <div className="absolute inset-0 transition-all duration-700 transform hover:scale-125">
                <img
                  src="/gg/gg4.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductGallery;
