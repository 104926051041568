import { memo } from "react";
import { Handle, Position, NodeResizer } from "reactflow";

const ResizableNode = ({ data }) => {
  return (
    <>
      <NodeResizer minWidth={300} minHeight={30} />
      <Handle type="target" position={Position.Left} />
      <div style={{ padding: 10 }}>{data.label}</div>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default memo(ResizableNode);
