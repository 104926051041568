import React from "react";
import Greeting from "../../components/Greeting";
import Hero from "../../components/Hero";
import FlowChart from "../../components/flow-charts";

const About = () => {
  return (
    <>
      <Hero greeting={`About`} />
      <Greeting />
      <FlowChart />
    </>
  );
};

export default About;
