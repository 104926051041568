import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { RiWhatsappFill } from "react-icons/ri";
import { HiMenuAlt3 } from "react-icons/hi";
import southtechLogo from "../../Assets/Southtech Logo (1)-1.jpg";

import { useGlobalContext } from "../../context";

const Navbar = () => {
  const sidebarRef = useRef(null);
  const { isSidebarOpen, sidebarClickHandler } = useGlobalContext();

  useEffect(() => {
    const handleScroll = () => {
      sidebarClickHandler(true);
    };

    const handlePopstate = () => {
      sidebarClickHandler(true);
    };
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Clicked outside of sidebar
        sidebarClickHandler(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("popstate", handlePopstate);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("popstate", handlePopstate);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleSidebar = () => {
    sidebarClickHandler(!isSidebarOpen);
  };

  return (
    <header className=" w-full bg-white flex flex-col ">
      <div className="h-auto pb-2 pt-2 md:py-4 bg-primary text-white text-sm lg:text-xl md:text-base padding-inside flex flex-col md:flex-row justify-center items-center md:space-x-16 gap-2">
        <p className="mb-0">Safety, Comfort & Fast Support....</p>
        <div className="flex gap-x-1 items-center">
          <span>
            <RiWhatsappFill />
          </span>
          <a href="https://wa.me/1713 0845 48" className="mb-0 cursor-pointer">
            +880 1713 0845 48
          </a>
        </div>
      </div>
      <nav className="w-full h-auto  py-3 flex justify-center sm:justify-around items-center relative md:padding-inside">
        <div className="flex-1 flex sm:block justify-center items-center py-3">
          <a href="/">
            <img
              src={southtechLogo}
              alt="SouthTech"
              className="w-[120px] sm:w-[280px]"
            />
          </a>
        </div>
        <div
          className="md:hidden cursor-pointer"
          onClick={handleSidebar}
          ref={sidebarRef}
        >
          <span className=" text-primary text-3xl ">
            <HiMenuAlt3 />
          </span>
        </div>
        <ul className="hidden menubar md:flex justify-end items-center space-x-8">
          <li>
            <NavLink
              exact
              to="/"
              type="button"
              className={`menu-btn hover:bg-primary hover:scale-105 `}
            >
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              type="button"
              className={`menu-btn hover:bg-primary hover:scale-105 `}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/service"
              type="button"
              className={`menu-btn hover:bg-primary hover:scale-105 `}
            >
              Service
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/product"
              type="button"
              className={`menu-btn hover:bg-primary hover:scale-105 `}
            >
              Product
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              type="button"
              className={`menu-btn hover:bg-primary hover:scale-105 `}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
