import React from "react";

import photo1 from "../../Assets/proudct/escalator-1.jpg";
import photo2 from "../../Assets/proudct/elevator-1.jpg";
import photo3 from "../../Assets/generator-2.jpg";
import mdPhoto from "../../Assets/Md. Monir Hossain.jpg";

import "./greeting.style.css";

const Greeting = () => {
  return (
    <section className="sm:px-12 px-2 w-full  flex flex-col justify-center items-center  my-12">
      <div className="px-5 section-features">
        <div className="mb-2 sm:mb-8 font-raleway ">
          <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
            Welcome to the SouthTech Engineering Limited
          </h2>
          <p className="w-full text-green text-center">
            We provide best service. We work for you
          </p>
        </div>
        <div>
          <p className="text-sm sm:text-lg font-nunito">
            <span className="text-primary">Southtech Engineering Ltd</span> is a
            Considering the experience and well reputation of the partner in the
            sector of Elevator, Escalator, Generator & Substation in the world
            market I have decided to start the Supply, Installation, Testing,
            Commissioning and after sales service of Elevator, Escalator,
            Generator & Substation in Bangladesh.
          </p>
          <div className="">
            <div className="grid sm:grid-cols-3 gap-3">
              <div>
                <img
                  src={photo1}
                  alt="service photo"
                  className="w-full h-full object-cover image"
                />
              </div>
              <div>
                <img
                  src={photo2}
                  alt="lift-photo"
                  className="w-full h-full object-cover image"
                />
              </div>
              <div>
                <img
                  src={photo3}
                  alt="cabin-photo"
                  className="w-full h-full object-cover image"
                />
              </div>
            </div>
            <p className="pt-5 text-sm sm:text-lg font-nunito">
              The main vision of Southtech Engineering Ltd. is to contribute to
              society through Elevator, Escalator, Generator, Substation
              business. I am proud to say that we have a fully equipped and
              enthusiastic engineering team with vastly experience. Increasing
              our domestic market’s requirements for best quality, dependable,
              riding safe and smooth operation elevator and escalator as per EN
              NORMS, Southtech Engineering Ltd. again introduces world class
              Elevator, Escalator, Generator & Substation.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-wrap md:flex-nowrap border-y-2 border-blue-400 justify-center gap-10 items-center py-5">
          <div className="min-w-[300px] h-[300px] bg-white rounded-full flex items-center justify-center overflow-hidden">
            <figure className="shape">
              <img src={mdPhoto} alt="Md Monir Hasan" className="shape-image" />
            </figure>
          </div>
          <div className=" flex flex-col">
            <div className="mt-16 order-1 md:order-0 pt-5 md:pt-0">
              <p className="pt-5 text-sm sm:text-lg font-nunito">
                <strong className="text-primary">
                  Southtech Engineering Ltd
                </strong>{" "}
                is solely concentrated in{" "}
                <strong className=" text-whitesmoke">
                  Elevator, Escalator, Generator and Substation business
                  including Elevator, Escalator, Generator and Substation
                  planning, supply, installation, maintenance and modernization.
                </strong>{" "}
                The company was- in the joint registrar of companies.
              </p>
              <p>
                Two professionals from this very industry who have long exposure
                and experience in Elevator, Escalators, Generator and Substation
                of different brands both home and abroad have established this
                company.
              </p>
              <p>
                The Managing Director of Southtech, Md. Monir Hossain, has over
                Twenty years experience in the Elevator industry in Bangladesh.
                He has been involved in the installation, commissioning and
                after sales service and maintenance of ThyssenKrupp Korea,
                Shanghai Mitsubishi, Toshiba Japan, AH & MET Turkey, DIAO China
                Elevator & Escalators.
              </p>
            </div>

            <div className="order-0 md:order-1 ">
              <h3 className="text-[26px] font-bold pt-3 text-end">
                Md. Monir Hossain
              </h3>
              <p className="italic text-end">Managing Director of Southtech</p>
            </div>
          </div>
        </div>

        <div className="text-black w-full px-5 py-5 text-2xl">
          <p className="pt-5 text-sm sm:text-lg font-nunito">
            <span className="font-bold text-xl sm:text-3xl">
              The Deputy Managing Director
            </span>
            ,{" "}
            <span className="font-bold text-primary">
              Md. Ehetashamul Islam Mithun
            </span>
            , is a professionally in operation of Generators & Substations. He
            has about Ten years’ experience in the Electric industry sector in
            Bangladesh. He has been involved in the installation, commissioning
            and after sales service and maintenance of Power sector,
            Transmission & Distribution system in a Global all kind of power
            generation system. He has personally overseen all operation of
            Generators & Substations installation, commissioning,
            troubleshooting, Modernization and project management as well as
            worked in senior managerial positions in different companies. Where
            he oversaw of Generators & Substations and managed the total
            operations.
          </p>
          <p className="pt-5 text-sm sm:text-lg font-nunito">
            SOUTHTECH is committed to run all its operations absolutely
            professionally by ensuring Safety & Securer and proper services to
            its clients, be it for a new supply and installation or for
            maintenance of Elevator, Escalators, Generator, and Substation or
            modernization of old Elevator, Escalators, Generator, Substation and
            service related all spare parts.
          </p>
        </div>
      </div>
      <div className="w-full">
        <div className="mb-2 sm:mb-8 font-raleway ">
          <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
            Our Mission, Vision & Values
          </h2>
        </div>
        <div className="grid sm:grid-cols-12 gap-5">
          <div className="text-whitesmoke sm:col-span-5">
            <h4 className="text-tahiti">
              Southtech Engineering Limited Mission Statement-----
            </h4>
            <p className="pt-5 text-sm font-nunito">
              The mission of Southtech Engineering Limited Group shall be
              "Engineering for Sustainable Growth of the Global community" This
              means Based on our qualifications and global first-rate
              engineering capabilities, we offer comprehensive solutions that
              fully meet the various requirements of our clients, and as part of
              our genuine attempt we wish to contribute to the realization of a
              "Sustainable Global Community" by harmonizing the supply of energy
              and basic materials with requirements for environmental
              protection. In order to achieve this mission, we will establish
              and maintain good relationships with our business partners on a
              mutually beneficial basis. All with the expectation of making our
              company a more attractive place to work for with pride. We
              consider it our Corporate Social Responsibility (CSR) to make
              contributions to the community through "engineering" itself, which
              means a method of integrating individual technologies into an
              optimized complete system as well as to carry out the above
              mission.
            </p>
          </div>
          <div className="text-whitesmoke sm:col-span-7">
            <h4 className="text-tahiti">
              Southtech Engineering Limited Vision Statement ----
            </h4>
            <p className="pt-5 text-sm font-nunito">
              Global Leading Engineering Partner Southtech Engineering Limited
              aims to be a "Global Leading Engineering Partner." This means: To
              become the most trusted and thus long-term partner to our clients
              by solving their problems together with and from the perspectives
              of the client. To provide our client with total value for the
              project in terms of and consisting of quality, safety, secure,
              schedule, cost, etc. by continuously improving our follow-on
              advantage capabilities: Engineering technologies: We can
              orchestrate individual pieces of technical information prepared by
              each design discipline into the comprehensive system information,
              which is precisely coordinated with and compatible to the
              procurement and/or the construction functionalities. High-tech
              application Capabilities: We always maintain and can make the best
              use of a variety of cutting-edge technologies of our proprietary
              and licensed, to solve our clients' challenges. To build a
              creation cycle of intellectual property by maintaining, managing
              and utilizing those intellectual properties acquired from the
              above activities and thus continue to satisfy the needs of our
              client. Our operation covers engineering, procurement and
              construction of industrial plants in an extensive range of fields;
              the provision of related technical services including master
              plans, operation and maintenance services, etc.; management and
              business consultation; all the way up to systems design and
              project development. In these business fields, we offer the most
              viable solutions and means for their realization, in the form of
              hardware and/or software, to assist our clients to optimize their
              business systems and value chains to enhance their corporate
              value. We respective local markets and clients and carry out work
              for any client in the most efficient manner based on common work
              standards.
            </p>
          </div>

          <div className="text-whitesmoke sm:col-span-12">
            <ul className="ml-5 grid grid-cols-2 ">
              <li className="list-item py-5">
                <h4 className="text-tahiti">
                  Southtech Engineering Limited Vision Statement ----
                </h4>
                <ul className="ml-5">
                  <li className="list-item">
                    &#8226; <span> Safety &amp; Secure Ensure</span>
                  </li>
                  <li className="list-item">
                    &#8226; <span> Quality Maintain</span>
                  </li>
                  <li className="list-item">
                    &#8226; <span> Create value for the customer</span>
                  </li>
                  <li className="list-item">
                    &#8226; <span> Integrity &amp; Trust</span>
                  </li>
                  <li className="list-item">
                    &#8226; <span> Accountability </span>
                  </li>
                </ul>
              </li>
              <li className="list-item py-5">
                <h4 className="mb-0 text-tahiti">Responsibility:</h4>
                <ul className="ml-5">
                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      Successful businesses improve the human condition.
                    </span>
                  </li>
                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We act with integrity and maintain the highest ethical
                      standards.
                    </span>
                  </li>

                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We act with integrity and maintain the highest ethical
                      standards.
                    </span>
                  </li>
                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We care for the health and safety of our employees and
                      customers.
                    </span>
                  </li>
                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We actively support the communities in which we do
                      business.
                    </span>
                  </li>
                </ul>
              </li>
              <li className="list-item py-5">
                <h4 className="mb-0 text-tahiti">Innovation:</h4>
                <ul className="ml-5">
                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We are a company of ideas, committed to research and
                      development.
                    </span>
                  </li>
                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      Our heritage inspires us to reach the next innovative,
                      powerful and marketable idea.
                    </span>
                  </li>

                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We continuously invest in new products, technologies and
                      intelligent systems.
                    </span>
                  </li>
                </ul>
              </li>
              <li className="list-item py-5">
                <h4 className="mb-0 text-tahiti">Customer Care:</h4>
                <ul className="ml-5">
                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We listen to our customers and respond to their needs.
                    </span>
                  </li>
                  <li className="list-item">
                    &#8226; <span>We make it easy to do business with us.</span>
                  </li>

                  <li className="list-item">
                    &#8226;{" "}
                    <span>
                      We are committed to excellence in customer satisfaction.
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-4 sm:my-12">
        <div className="mb-2 sm:mb-8 font-raleway ">
          <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
            Our Achievement
          </h2>
        </div>
        <div>
          <h4 className="text-[18px] sm:text-[21px] text-green text-center tracking-wider font-bold">
            Authorization From Shenyang Intellectual Industry Group Co. Ltd.
          </h4>
          <div className="w-3/4 mx-auto mt-3">
            <img
              src="/certificate1.jpg"
              alt="certificate"
              className="w-auto h-auto"
            />
          </div>
        </div>
        <div className="mt-5">
          <h4 className="text-[18px] sm:text-[21px] text-green text-center tracking-wider font-bold">
            Authorization From Sino Hyundai
          </h4>
          <div className="w-3/4 mx-auto mt-3">
            <img
              src="/certificate2.jpg"
              alt="certificate"
              className="w-auto h-auto"
            />
          </div>
        </div>
        <div className="mt-5">
          <h4 className="text-[18px] sm:text-[21px] text-green text-center tracking-wider font-bold">
            Authorization From Suzhou Elevetor Co. Ltd.
          </h4>
          <div className="w-3/4 mx-auto mt-3">
            <img
              src="/certificate3.jpg"
              alt="certificate"
              className="w-auto h-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Greeting;
