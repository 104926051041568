import React from "react";
import { GiElevator } from "react-icons/gi";
import elevator from "../../Assets/elevator-logo.png";
import escalator from "../../Assets/escalator-logo (2).png";
import motors from "../../Assets/Generator.png";
import substitution from "../../Assets/Substitustion.png";

const Advartisment = ({ props }) => {
  return (
    <div className="w-full py-4 mb-8 h-52 flex bg-secondary justify-around items-center">
      <p className="text-white flex flex-col justify-center items-center mt-6 w-1/3 gap-4">
        <span>
          <img src={elevator} alt="elevator" />
        </span>
        <span className="text-2xl font-bold">Elevator</span>
      </p>

      <p className="text-white flex flex-col justify-center items-center mt-6 w-1/3 gap-4">
        <span className="">
          <img src={escalator} alt="elevator" />
        </span>
        <span className="text-2xl font-bold">Escalator</span>
      </p>
      <p className="text-white  flex flex-col justify-center items-center mt-6 w-1/3 gap-4">
        <span>
          <img src={motors} alt="elevator" />
        </span>
        <span className="text-2xl font-bold">Generator</span>
      </p>
      <p className="text-white  flex flex-col justify-center items-center mt-6 w-1/3 gap-4">
        <span>
          <img src={substitution} alt="elevator" />
        </span>
        <span className="text-2xl font-bold">Substation</span>
      </p>
    </div>
  );
};

export default Advartisment;
