import React, { useEffect, useRef } from "react";

import { FaHome } from "react-icons/fa";
import { FcAbout, FcServices } from "react-icons/fc";
import { MdProductionQuantityLimits } from "react-icons/md";
import { CiMicrophoneOn } from "react-icons/ci";

import { useGlobalContext } from "../../context";

import "./sidebar.style.css";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const { isSidebarOpen, sidebarClickHandler } = useGlobalContext();

  return (
    <div
      className={`absolute h-screen w-2/4 bg-secondary top-50 left-0 z-40 md:hidden ${
        isSidebarOpen ? `sidebar-anim` : `sidebar-anim show_sidebar`
      }`}
    >
      <ul className="flex flex-col justify-center items-start font-bold text-2xl">
        <li className="w-full">
          <NavLink
            exact
            to="/"
            className="px-10 py-5 bg-secondary flex space-x-5 w-full text-whitesmoke hover:text-black shadow-md"
          >
            <FaHome />
            <span>Home</span>
          </NavLink>
        </li>
        <li className="w-full">
          <NavLink
            to="/about"
            className="px-10 py-5 bg-secondary flex space-x-5 w-full text-whitesmoke hover:text-black shadow-md"
          >
            <FcAbout />
            <span>About Us</span>
          </NavLink>
        </li>
        <li className="w-full">
          <NavLink
            to="/service"
            className="px-10 py-5 bg-secondary flex space-x-5 w-full text-whitesmoke hover:text-black shadow-md"
          >
            <FcServices />
            <span>Service</span>
          </NavLink>
        </li>
        <li className="w-full">
          <NavLink
            to="/product"
            className="px-10 py-5 bg-secondary flex space-x-5 w-full text-whitesmoke hover:text-black shadow-md"
          >
            <MdProductionQuantityLimits />
            <span>Products</span>
          </NavLink>
        </li>
        <li className="w-full">
          <NavLink
            to="/contact"
            className="px-10 py-5 bg-secondary flex space-x-5 w-full text-whitesmoke hover:text-black shadow-md"
          >
            <CiMicrophoneOn />
            <span>Contact Us</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
