import React from "react";

import Hero from "../../components/Hero";
import Services from "../../components/sevices";

const Service = () => {
  return (
    <>
      <Hero greeting={`Our Service`} type={`service`} />
      <div className="mt-10">
        <Services />
        <div className="px-5">
          <p className="">
            This service provides expert advice and recommendations to clients
            in order to identify and implement the best possible solutions to
            meet their needs. Consultancy services typically involve an
            assessment of the client's specific requirements, followed by the
            development of a tailored plan to achieve their goals. This can
            include guidance on equipment selection, maintenance, and other
            related issues. The aim of this service is to help clients make
            informed decisions and achieve their desired outcomes.
          </p>
        </div>
      </div>
    </>
  );
};

export default Service;
