import { Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/footer/footer";

import About from "./Page/About Us";
import Service from "./Page/Service";

import HomePage from "./Layout/Homepage";
import Sidebar from "./components/sidebar";
import { useGlobalContext } from "./context";
import Products from "./Page/Product";
import ContactUs from "./Page/Contact us";

function App() {
  const { sidebarClickHandler } = useGlobalContext();

  return (
    <>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/product" element={<Products />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
