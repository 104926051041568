import React from "react";

import { FaRegHandPointRight } from "react-icons/fa";

import ServicePhoto from "../../Assets/sava-bobov-nPc9sDIqyfI-unsplash.jpg";
import ServicePhoto2 from "../../Assets/pexels-cottonbro-studio-8453040.jpg";
import CabinDesign from "../../Assets/Cabin Design-6.jpg";
import Lift from "../../Assets/panoramic-observation-elevators.jpg";
import COP from "../../Assets/COP-5.jpg";
import Motor from "../../Assets/generator11.png";
import escalator from "../../Assets/Escalator Photo-8.jpg";
import Button from "../button/button";

const Service = () => {
  return (
    <section className="sm:px-12 px-2 w-full  flex flex-col justify-center items-center  my-12">
      <div className="mb-2 sm:mb-8 font-raleway ">
        <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
          Our Services
        </h2>
        <span className="text-green">
          24 hour services, Expert Services Tailored to Your Needs
        </span>
      </div>
      <div className="mt-2 sm:mt-10 px-5 md:grid md:grid-cols-2 md:gap-12">
        <ul className="flex flex-col gap-3 sm:gap-6 text-xl font-nunito">
          <li className="flex gap-2 sm:gap-5">
            <FaRegHandPointRight className="text-primary" />
            <strong>
              Consultancy services to advise best possible solutions.
            </strong>
          </li>
          <li className="flex gap-2 sm:gap-5">
            <FaRegHandPointRight className="text-primary" />
            <strong>Through Planning for smooth execution</strong>
          </li>
          <li className="flex gap-2 sm:gap-5">
            <FaRegHandPointRight className="text-primary" />
            <strong>
              Timely supply & Installation of Equipment of best quality.
            </strong>
          </li>
          <li className="flex gap-2 sm:gap-5">
            <FaRegHandPointRight className="text-primary" />
            <strong>
              Effective preventive service & maintenance programs.
            </strong>
          </li>
          <li className="flex gap-2 sm:gap-5">
            <FaRegHandPointRight className="text-primary" />
            <strong>
              Efficient breakdown & faulty problems maintenance support
              services.
            </strong>
          </li>
        </ul>
        <div className="w-3/4 relative hidden md:block">
          <img src={COP} alt="service photo" className="x-photo photo-1" />
          <img src={escalator} alt="cabin-photo" className="x-photo photo-2" />
          <img src={Motor} alt="lift-photo" className="x-photo photo-3" />
        </div>

        <div className="w-full mt-14 relative grid grid-cols-3    gap-5 md:col-span-2">
          <img
            src={ServicePhoto}
            alt="service photo"
            className="w-full h-full object-cover image"
          />
          <img
            src={Lift}
            alt="lift-photo"
            className="w-full h-full object-cover image"
          />
          <img
            src={CabinDesign}
            alt="cabin-photo"
            className="w-full h-full object-cover image"
          />
        </div>
      </div>
      <div className="mt-3 sm:mt-6 flex flex-col gap-6 md:grid md:grid-cols-12 md:p-10 transition-all delay-700">
        <div className="md:order-1 sm:col-span-7">
          <p className="text-sm sm:text-lg font-nunito">
            <strong className="text-semibold text-tahiti ">
              Southtech Engineering Ltd
            </strong>{" "}
            is a Considering the experience and well reputation of the partner
            in the sector of Elevator, Escalator, Generator & Substation in the
            world market I have decided to start the Supply, Installation,
            Testing, Commissioning and after sales service of Elevator,
            Escalator, Generator & Substation in Bangladesh.
          </p>
          <h2 className="mb-2 text-secondary  text-[18px] sm:text-[2rem] font-raleway tracking-[.05em] font-semibold">
            VIP Service:
          </h2>
          <p className="text-sm sm:text-lg font-nunito">
            When our customers host important events (e.g. opening ceremonies,
            large conferences etc.) or receive distinguished leaders or VIPs,
            you can notify SOUTHTECH Engineering's Maintenance Department in
            advance and we will inspect the relevant elevators especially for
            you while deploying experienced technicians to supply stand-by
            services.
          </p>
          <h2 className="mb-2 text-secondary  text-[18px] sm:text-[2rem] font-raleway tracking-[.05em] font-semibold">
            Regular Service:
          </h2>
          <p className="text-sm sm:text-lg font-nunito">
            SOUTHTECH conducts regular services via its preventive service
            program – typical intervals for regular elevator and escalator
            services are four weeks.
          </p>
        </div>
        <div className="sm:col-span-5 overflow-hidden rounded-xl w-full md:order-0  transition-all delay-700">
          <div className="hover:scale-110 transition-all delay-700">
            <img
              src={ServicePhoto2}
              alt="service photo"
              className="w-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
