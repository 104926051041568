import React from "react";

import Hero from "../../components/Hero";
import ProductGallery from "../../components/product-gellary";

const Products = () => {
  return (
    <>
      <Hero greeting={`Our Products`} type={`Product`} />
      <div className="mt-10">
        <ProductGallery />
      </div>
    </>
  );
};

export default Products;
