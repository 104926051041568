import React from "react";

import Hero from "../../components/Hero";
import Advartisment from "../../components/adv-component";
import Product from "../../components/product";
import Service from "../../components/sevices";
import Reason from "../../components/Reason";
import WorkingInfo from "../../components/our-work";
import FlowChart from "../../components/flow-charts";

const HomePage = () => {
  return (
    <>
      <Hero greeting={`Welcome To`} />
      <Advartisment />
      <Product />
      <WorkingInfo />
      <Service />
      <Reason />
      <FlowChart />
    </>
  );
};

export default HomePage;
