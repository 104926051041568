import React from "react";

const WorkingInfo = () => {
  return (
    <div className="w-full h-[8rem] bg-secondary pt-10 px-5 text-center">
      <span class="text-[1.4rem] text-center max-w-[70px]  bg-clip-text bg-gradient-to-r from-[#FF0000] to-[#FFFF00] via-[#FFFFFF] text-transparent">
        Trusted Provider In Elevator, Escalator, Generator & Substation
        Services. We serve more than{" "}
        <span className="text-2xl font-bold text-white">100</span> customers
      </span>
    </div>
  );
};

export default WorkingInfo;
