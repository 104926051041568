import React from "react";

import { NavLink } from "react-router-dom";

import Elevator from "../../Assets/Cabin Design-4.jpg";
import Escalator from "../../Assets/Escalator Photo-2.jpg";
import Generator from "../../Assets/generator-1.jpg";
import Card from "../card";

import cabineDesign from "../../Assets/proudct/Cabin Design-1.jpg";
import elevator from "../../Assets/proudct/elevator-1.jpg";
import elscalator from "../../Assets/proudct/escalator-2.jpg";
import el2 from "../../Assets/proudct/escalator-1.jpg";
import el3 from "../../Assets/proudct/COP-1.jpg";
import el4 from "../../Assets/proudct/Gearless Motor-4.jpg";
const Product = () => {
  return (
    <section className="sm:px-12 px-2 w-full  flex flex-col justify-center items-center  my-12">
      <div className="mb-2 sm:mb-8 font-raleway ">
        <h2 className=" text-secondary text-center text-[20px] sm:text-[2.7rem] tracking-[.075em] font-bold">
          Our Products
        </h2>
        <span className="text-green">
          We provide best service. We work for you
        </span>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 w-full   gap-3 transition-all mt-2 sm:mt-10">
        <div className="w-full flex flex-col gap-6 hover:scale-110 ease-in-out duration-300 ">
          <NavLink to="/product" className="w-full sm:h-96 cursor-pointer">
            <img
              src={elevator}
              alt=""
              className="w-full h-full object-contain relative"
            />
          </NavLink>

          <h3 className="text-center">Elevator</h3>
        </div>
        <div className="w-full flex flex-col gap-6  hover:scale-110 ease-in-out duration-300 ">
          <NavLink to="/product" className="w-full sm:h-96 cursor-pointer">
            <img
              src={el2}
              alt=""
              className="w-full h-full object-contain relative"
            />
          </NavLink>

          <h3 className="text-center">Escalator</h3>
        </div>
        <div className="w-full flex flex-col gap-6 hover:scale-110 ease-in-out duration-300 ">
          <NavLink to="/product" className="w-full sm:h-96 cursor-pointer">
            <img
              src={el3}
              alt=""
              className="w-full h-full object-contain relative"
            />
          </NavLink>

          <h3 className="text-center">COP</h3>
        </div>
        <div className="w-full flex flex-col gap-6 hover:scale-110 ease-in-out duration-300 ">
          <NavLink to="/product" className="w-full sm:h-96 cursor-pointer">
            <img
              src={cabineDesign}
              alt=""
              className="w-full h-full object-contain relative"
            />
          </NavLink>

          <h3 className="text-center">Cabin Design</h3>
        </div>
        <div className="w-full flex flex-col gap-6 hover:scale-110 ease-in-out duration-300 ">
          <NavLink to="/product" className="w-full sm:h-96 cursor-pointer">
            <img
              src={Generator}
              alt=""
              className="w-full h-full object-contain relative"
            />
          </NavLink>

          <h3 className="text-center">Generator</h3>
        </div>
        <div className="w-full flex flex-col gap-6 hover:scale-110 ease-in-out duration-300 ">
          <NavLink to="/product" className="w-full sm:h-96 cursor-pointer">
            <img
              src={el4}
              alt=""
              className="w-full h-full object-contain relative"
            />
          </NavLink>

          <h3 className="text-center">Gearless Motor</h3>
        </div>
      </div>
    </section>
  );
};

export default Product;
