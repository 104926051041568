import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { photos } from "../../utils/sliderImage";
import Button from "../button/button";
import "./hero.style.css";

const Hero = ({ greeting, type }) => {
  const [photo, setPhoto] = useState(photos);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = photo.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, photo]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 6000);
    return () => clearInterval(slider);
  }, [index]);
  return (
    <section className="hero-container ">
      <div className="hero-slider">
        {photo.map((singleImage, imageIndex) => {
          const { id, imageUrl, name } = singleImage;

          // slider logic
          let position = "nextSlide";
          if (imageIndex === index) {
            position = "activeSlide";
          }
          if (
            imageIndex === index - 1 ||
            (index === 0 && imageIndex === singleImage.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <div className={`${position} slider-image`}>
              <img src={singleImage.url} alt={singleImage.caption} />
            </div>
          );
        })}
      </div>
      <div className="hero-content">
        <div className="flex flex-col  items-center justify-center mb-12">
          <div className=" font-nunito font">
            <h2 className="mb-2 text-white text-center text-[18px] sm:text-[2rem] font-raleway tracking-[.05em] font-semibold">
              {greeting}
            </h2>
            {type ? (
              ""
            ) : (
              <h1 className="mb-4 text-white text-center text-[20px] sm:text-[2.7rem] font-raleway tracking-[.075em] font-bold">
                <span className="text-[#14afe4]">SOUTHTECH</span> ENGINEERING
                LIMITED
              </h1>
            )}
            <p className=" text-center text-[#FFFF00]">
              Safety & Secure Ensurer
            </p>
          </div>

          <div className="hero-button">
            <Link to="/contact" className="">
              {type === "contact" ? (
                ""
              ) : (
                <Button content="Contact Us" type="solid" />
              )}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
